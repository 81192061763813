import { create } from 'zustand';
import { persist } from 'zustand/middleware';  // persist 미들웨어를 import

export const useConceptsStore = create(
  persist(
    (set) => ({
      concepts: [],
      setConcepts: (newConcepts) => set((state) => {
        const uniqueConcepts = newConcepts.filter(newItem => 
          !state.concepts.some(existingItem => existingItem.id === newItem.id)
        );
        return {
          concepts: [...state.concepts, ...uniqueConcepts],
        };
      }),
      newSetConcepts: (newConcepts) => set({ concepts: newConcepts }),  // 데이터 덮어쓰기
    }),
  
    {
      name: 'concepts-storage', // 로컬스토리지의 키
      getStorage: () => localStorage, // 로컬스토리지 사용
    }
  )
);


export const useBrowserStore = create(
  persist(
    (set) => ({
      browserType: "",  // 브라우저 타입을 저장할 상태
      setBrowserType: (type) => set({ browserType: type }),  // 브라우저 타입을 설정하는 액션
    }),
    {
      name: "browser-storage", // 로컬 스토리지에 저장되는 이름
    }
  )
);


const useAuthStore = create(
  persist(
    (set) => ({
      isLoggedIn: false,
      login: () => set({ isLoggedIn: true }),
      logout: () => set({ isLoggedIn: false }),
    }),
    {
      name: 'auth-storage', // 로컬스토리지에 저장될 키 이름
    }
  )
);

export default useAuthStore;