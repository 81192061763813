import React, { useState } from 'react';
import styled from 'styled-components';




export const DropdownList = styled.ul`
  background-color: white;
  position: absolute;
  list-style: none;
  padding: 0;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const DropdownList2 = styled.ul`
  background-color: white;

  list-style: none;
  padding: 0;
  margin-top: 0px;
  
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;


export const DropdownItem = styled.li`
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    color: #F68062;
  }
`;

export const DropdownItem2 = styled.li`
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    color: #F68062;
  }
`;

export const DropdownButton = styled.button`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E5E7EB;
  border-radius: 8px;

  &:hover {
    background-color: #FFF7ED;
    border-color: #FFF7ED;
  }
`