import React, { useState } from "react";
import {
  DropdownContainer,
  DropdownList,
  DropdownSelect,
} from "../style/DropdownStyled";
import { IoIosArrowDown } from "react-icons/io";
import { DropdownItem } from "../style/DropdownStyled";
import { useTranslation } from "react-i18next";
import ko from "../../../assets/img/national/kr.png";
import en from "../../../assets/img/national/us.png";
import th from "../../../assets/img/national/th.png";
import ja from "../../../assets/img/national/ja.png";
import { useURLStore } from "../../../store/url/UrlStore";
import i18n from "../../../i18n";
import { useLocation, useNavigate } from "react-router-dom";

function Dropdown() {
  // 드롭다운 선택값 상태 관리
  const location = useLocation();
  const navigate = useNavigate();
  const language = useURLStore((state) => state.language);
  const [selectedOption, setSelectedOption] = useState(language);
  const [isOpen, setIsOpen] = useState(false); // 드롭다운 열림/닫힘 상태
  const { t } = useTranslation();
  const setLanguage = useURLStore((state) => state.setLanguage);
  // 드롭다운 선택 변경 시 호출되는 함수
  const handleSelect = (value) => {
    const currentUrl = location.pathname; // 현재 URL 가져오기
    const updatedUrl = currentUrl.replace(
      /\/(ja|ko|en|th)(\/|$)/,
      `/${value}$2`
    );
    setSelectedOption(value);
    setLanguage(value);
    setIsOpen(false);
    navigate(updatedUrl, { replace: true });
  };

  return (
    <div>
      {/* 드롭다운 버튼 */}
      <button
        id="dropdownbutton"
        className="text-sm"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          padding: "10px",
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
        }}
      >
        {selectedOption === "ko" && (
          <div
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
          >
            <div
              style={{
                width: `20px`,
                marginRight: `5px`,
              }}
            >
              <img
                src={ko}
                style={{
                  width: `100%`,
                  height: `100%`,
                }}
              ></img>
            </div>
            <div>한국어</div>
          </div>
        )}
        {selectedOption === "en" && (
          <div
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
          >
            <div
              style={{
                width: `20px`,
                marginRight: `5px`,
              }}
            >
              <img
                src={en}
                style={{
                  width: `100%`,
                  height: `100%`,
                }}
              ></img>
            </div>
            <div>English</div>
          </div>
        )}
        {selectedOption === "ja" && (
          <div
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
          >
            <div
              style={{
                width: `20px`,
                marginRight: `5px`,
              }}
            >
              <img
                src={ja}
                style={{
                  width: `100%`,
                  height: `100%`,
                }}
              ></img>
            </div>
            <div>日本語</div>
          </div>
        )}
        {selectedOption === "th" && (
          <div
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
          >
            <div
              style={{
                width: `20px`,
                marginRight: `5px`,
              }}
            >
              <img
                src={th}
                style={{
                  width: `100%`,
                  height: `100%`,
                }}
              ></img>
            </div>
            <div>ไทย</div>
          </div>
        )}
        <div className="ml-2">
          <IoIosArrowDown />
        </div>
      </button>

      {/* 드롭다운 옵션 리스트 */}
      {isOpen && (
        <DropdownList>
          <DropdownItem id="ko" onClick={() => handleSelect("ko")}>
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <img
                src={ko}
                style={{
                  height: `10px`,
                  width: `15px`,
                  marginRight: `3px`,
                }}
              ></img>
              한국어
            </div>
          </DropdownItem>
          <DropdownItem id="en" onClick={() => handleSelect("en")}>
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <img
                src={en}
                style={{
                  height: `10px`,
                  width: `15px`,
                  marginRight: `3px`,
                }}
              ></img>
              English
            </div>
          </DropdownItem>
          <DropdownItem id="ja" onClick={() => handleSelect("ja")}>
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <img
                src={ja}
                style={{
                  height: `10px`,
                  width: `15px`,
                  marginRight: `3px`,
                }}
              ></img>
              <div
                style={{
                  fontFamily: "DefaultFont",
                }}
              >
                日本語
              </div>
            </div>
          </DropdownItem>
          <DropdownItem id="th" onClick={() => handleSelect("th")}>
            <div
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <img
                src={th}
                style={{
                  height: `10px`,
                  width: `15px`,
                  marginRight: `3px`,
                }}
              ></img>
              ไทย
            </div>
          </DropdownItem>
        </DropdownList>
      )}
    </div>
  );
}

export default Dropdown;
