export const isKorean = (language) => {
  return language === 'ko' || language === 'ko-KR';
  };
  
  export const getLocaleText = (koText, enText, language) => {
    return isKorean(language) ? koText : enText;
  };


  export const getLocaleText2 = (language, title_ko, title_en, title_ja, title_th) => {

    switch (language) {
      case "ko":
        return title_ko ; 
      case "ja":
        return title_ja ;  
      case "th":
        return title_th ;  
      default:
        return title_en; 
    }
  };
  