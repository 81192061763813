import { useNavigate } from "react-router-dom";
import { useLoggingStore } from "../../store/logs/LogsStore";
import { useURLStore } from "../../store/url/UrlStore"
import { useQuery } from "@tanstack/react-query";
import { fetchMydata } from "../../lib/api/login/GetMyData";
import { useLoginStore } from "../../store/login/LoginStore";
import { useEffect, useState } from "react";
import { setCookie } from "../../lib/util/Cookie";
import { LogsStatusAPI } from "../../lib/api/common/Resources";
import api from "../../lib/api/apiConfig";
import { TokenExpire, TokenName } from "../../lib/util/Common";






const FaceBookRedirectURL = () => {
    const language = useURLStore((state) => state.language);
    const setLoggingData = useLoggingStore((state) => state.setLoggingData);

    const navigate = useNavigate();
    const {data, isLoading, isError, error, refetch} = useQuery({
        queryKey: ['mydata'],
        queryFn: fetchMydata,
        keepPreviousData: true,
        enabled: false,
    });

    const { setMydata, setFirstLogin, setLoginReword} = useLoginStore();
	const [firstLogin2,setFirstLogin2] = useState(false);
	const [isReword, setReword] = useState(null);

    useEffect(() => {
        if (data) {
            setMydata(data)
            setFirstLogin(firstLogin2)
            setLoginReword(isReword);
            navigate(`/${language}`)
        }
    },[data])
    useEffect(() => {
        const fetchAuthData = async () => {
			const error = new URL(window.location.href).searchParams.get("error");
            if (error) {
                navigate(`/${language}/login`)
                return false
            }
            try {
                const code = new URL(window.location.href).searchParams.get("code");
				const state = new URL(window.location.href).searchParams.get("state");
				const response = await api.get(`/api/v1/auth/fasebook/callback?code=${code}&state=${state}`);
				const logs  =  await LogsStatusAPI();
                const tokenData = response.data;
                if (response.status == 200) {
                    setCookie(TokenName, tokenData.access_token, { path: "/", maxAge: TokenExpire });
                    refetch()
                    setMydata(data)
					setFirstLogin(tokenData.is_first_login)
					setFirstLogin2(tokenData.is_first_login)
					setReword(tokenData.login_reword)
					setLoggingData(logs.data)
                }
            } catch(error) {
                console.log("페이스북 로그인 에러", error);
                navigate(`/${language}`);
            } finally {

            }
        };
        fetchAuthData();
    },[navigate])
    const mydata = useLoginStore((state) => state.mydata);

    if (isLoading) {
        return (
            <div>

            </div>
        )
    }
}

export default FaceBookRedirectURL;